@charset "UTF-8";

#shot-chartp1 {
  /* width: 100%; */
  /* margin-top: 0px; */
  /* margin-left: 10%; */
  /* display: "flex"; */
  /* justify-content: center; */

  /* margin-left: 25px; */
  /* margin-right: 15px; */
}  

/************* SHOTCHART COURT STYLES ****************/
/* #shot-chart-container {
  margin-top: -22%;
  margin-bottom: 5%;
}

@media (min-width: 50em) {
  #shot-chart-container {
    margin-top: -15%;
  }
}

@media (min-width: 55em) {
  #shot-chart-container {
    margin-top: -10%;
  }
}

@media (min-width: 78em) {
  #shot-chart-container {
    padding-left: 1em;
    margin-left: 33%;
  }
}

#shot-chart-container .shot-chart {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 78em) {
  #shot-chart-container .shot-chart {
    margin: 0;
  }
} */

.sc {
  /* margin-left: 60px;
  text-align: center; */
  /* display: flex; */
  /* justify-items: center; */
  /* justify-content: center; */

  margin: 0 auto;
  /* width: 80em; */

}

.sc svg {
  stroke-width: .2;
}


.sc svg text {
  font-size: 0.2em;
}

.sc svg text.legend-text {
  font-size: 0.1em;
}

.sc svg rect {
  stroke: white;
  fill: none;
}

.sc svg line {
  stroke: wheat;
  fill: none;
}

/********** END SHOTCHART COURT STYLES *******/

/******** SHOT STYLES *******/
.sc svg circle {
  stroke: white;
  fill: none;
}

.sc svg circle.shot.make {
  fill: green;
  stroke: white;
  stroke-width: 0.05;
}

.sc svg circle.shot.miss {
  fill: red;
  stroke: white;
  stroke-width: 0.05;
}

.sc path {
  stroke: white;
  stroke-width: 0.05;
}

.sc path.shot {
  stroke: white;
  fill: none;
  stroke-width: 0.05;
}
/********** END SHOT STYLES *****/
/******* SHOT TOOLTIPS ********/
.d3-tip {
  line-height: 1; 
  font-weight: lighter;
  padding: 12px;
  background: #006886;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: #006886;
  position: absolute;
  pointer-events: none;
}

/* Northward tooltips */
.d3-tip.n:after {
  content: "▼";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
  content: "◀ ";
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */
.d3-tip.w:after {
  content: "▲";
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
  content: "▶";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}